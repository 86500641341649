<template>
  <!-- 实验室用房介绍 -->
  <div class="main-contain">
    <template v-if="intro">
      <div class="text">
        {{ intro }}
      </div>
    </template>
    <template v-else>
      <div class="is-empty" style="text-align: center;">
        <img :src="no_math" class="no_math">
        <div style="font-size: 24px; font-weight: bold; color: #b5a2a2;margin-bottom: 30px;">
          <span>暂无实验用房介绍</span>
        </div>
      </div>
    </template>
    <!-- <div class="text">
      &emsp;&emsp;打通教学科研实验室壁垒，统筹教学科研实验室资源，促进科研支持实验教学、服务人才培养，把科研成果转化为实验教学内容，将科研方法融入实验教学活动，向学生传授科研理念、科研文化、科研价值，使学生了解科技最新发展和学术前沿动态，激发科研兴趣，启迪科研思维，培养科研道德，提升学生科学研究和科技创新的能力。
    </div> -->
  </div>
</template>
<script>
export default {
  name: "Courseintroduction",
  components: {},
  data() {
    return {
      intro: '',
      no_math: require('@/assets/no_math1.png'),
    };
  },
  methods: {
    initdata(obj) {
      this.intro = obj.intro
    }
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  margin-top: 20px;

  .text {
    margin-top: 10px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
  }

  @media screen and (max-width: 640px) {
    .no_math {
      width: 100%;
    }
  }
}
</style>
